$(function () {

    // new RecaptchaEvents();

    $('.timeline-title').click(function () {
        let $parent = $(this).parent();
        console.log($parent);
        $parent.toggleClass('open');
    });

    $('.hero-spotify iframe .ai').hide();

    // Gallery lightbox
    $('.video-wrapper').lightGallery({
        download: false,
        counter: false,
        loop: true,
        selector: '.lightbox-video-item',
        enableSwipe: true
    });

    $('.instagram-wrapper').lightGallery({
        download: false,
        counter: false,
        selector: '.instagram-video-item',
        enableSwipe: true
    });

    $('.tab-item').click(function () {
        let $tabId = $(this).attr('id');
        let $tab = $('.tab-item');
        let $content = $('.tab-content');

        $(this).addClass('active');
        $(this).attr('data-origin-active', true);
        $tab.not(this).removeClass('active');
        $tab.not(this).removeAttr('data-origin-active');

        $content.each(function () {
            let $contentId = $(this).attr('id');

            if($tabId === $contentId) {
                $(this).fadeIn();
                $content.not(this).fadeOut();
            }
        });
    });

    if ($('#hideBanner')) {
        $('#hideBanner').click(function (event) {
            event.preventDefault();
            event.stopPropagation();

            const $parent = $(this).closest('.banner');

            $parent.removeClass('banner--show');

            setTimeout(function () {
                $parent.css('display', 'none');
            }, 500);
        });
    }

    new window.RecaptchaEvents();

    // $(window).load(function() {
    //
    //     $('.parallax-container .parallax-item').addClass('show');
    // });

    window.onload = function() {
        $('.parallax-container .parallax-item').addClass('show');
        setTimeout(function () {
            $('.preloader').fadeOut();
            $('.home-page').removeClass('no-scroll');
        }, 3000);
    };

    const instaLoader = function() {
        $(".image-item").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const timelineLoader = function() {
        $(".inner-list-item").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const aboutImgLoader = function() {
        $(".about-us .about-img img").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const aboutAwardLoader = function() {
        $(".about-us .awards .award-list ul li").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const concertItemLoader = function() {
        $(".concerts-page .concerts-item").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const albumItemLoader = function() {
        $(".album-card ul li").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const galleryCardLoader = function() {
        $(".gallery-card").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    const galleryImageLoader = function() {
        $(".gallery-view-item img").each(function( index ) {
            if ($(this).visible(true)) {
                $(this).addClass('visible');
            } else {
                $(this).removeClass('visible');
            }
        });
    };

    instaLoader();
    timelineLoader();
    aboutImgLoader();
    aboutAwardLoader();
    concertItemLoader();
    albumItemLoader();
    galleryCardLoader();
    galleryImageLoader();

    $(window).scroll(function() {
        instaLoader();
        timelineLoader();
        aboutImgLoader();
        aboutAwardLoader();
        concertItemLoader();
        albumItemLoader();
        galleryCardLoader();
        galleryImageLoader();
    });

    $('.tab-item').on('mouseenter', function () {
        const $tab = $(this);
        const $parent = $tab.parent();
        const $line = $tab.siblings('hr');

        const tabClientRect = $tab.get(0).getBoundingClientRect();
        const parentClientRect = $parent.get(0).getBoundingClientRect();

        const width = $tab.outerWidth();
        const left = tabClientRect.left - parentClientRect.left;
        // const top = tabClientRect.top - parentClientRect.top + tabClientRect.height;

        if(!$tab.hasClass('active')) {
            $parent.find('.tab-item.active').removeClass('active').attr('data-origin-active', true);
            $line.css({width, left});
        }

    }).on('mouseleave', function () {
        const $tab = $(this);
        const $parent = $tab.parent();
        const $line = $tab.siblings('hr');

        if(!$tab.hasClass('active')) {
            $parent.find('[data-origin-active]').addClass('active').removeAttr('data-origin-active');
            $line.css('width', '');
        }
    });


    if($(window).width() > 1200) {

        window.wasScrolled = false;

        if($('body').hasClass('home-page')) {
            $(window).one('wheel',debounce(function(e){
                if (!window.wasScrolled){
                    $('html, body').animate({
                        scrollTop:document.getElementById('concerts').getBoundingClientRect().top
                    },1500, () => {
                        $('.first-scroll').removeClass('first-scroll');
                    })
                }
                window.wasScrolled = true;
            }, 200));
        }

        function debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        }

    } else {
        $('body').removeClass('first-scroll');
    }

});

