$(function(){
    // Nyelvválasztó mobil nézeten
    if($(window).innerWidth() <= 1200) {
        $('.langs ul').prepend($('.langs-active'));
    }

    // Mobil menü nyitás/csukás
    $('.mobile-menu').on('click', function (e) {
        $('.navbar').toggleClass('open');
        $('.overlay').toggleClass('open');
        $('.menu-sidebar').toggleClass('open');
        $(this).toggleClass('open');
        $('body').toggleClass('open');
        $('html').toggleClass('open');
    });

    $('html').click(function(event) {
        if ($(event.target).closest('.menu-sidebar, .mobile-menu').length === 0) {
            $('.navbar').removeClass('open');
            $('.overlay').removeClass('open');
            $('.menu-sidebar').removeClass('open');
            $('.mobile-menu').removeClass('open');
            $('body').removeClass('open');
            $('html').removeClass('open');
        }
    });

    // $(document).click(function (e){
    //
    //     let $container = $('.menu-sidebar');
    //
    //     if (!$container.is(e.target) && $container.has(e.target).length === 0){
    //         $container.removeClass('open');
    //     }
    // });

    // Több szintű dropdown
    $('.menu-item-dropdown').on("click", function(e){
        e.stopPropagation();

        let $this = $(this);
        let $ul = $(this).children('ul');
        let $span = $(this).children('span');
        let $target = $(e.target);

        $this.siblings().removeClass('open');
        $this.siblings().find('.menu-item-dropdown').removeClass('open');
        $this.siblings().find('span').removeClass('open');

        if(!$target.is('ul')) {
            if ($this.hasClass('open')) {
                $this.removeClass('open');
                $span.removeClass('open');
            } else {
                $this.addClass('open');
                $span.addClass('open');
            }
        }
    });

    // Dropdown bezárása ha máshova kattintunk
    $(document).click(function (e){

        let $container = $('.menu-item-dropdown');
        let $span = $container.children('span');

        if (!$container.is(e.target) && $container.has(e.target).length === 0){
            $container.removeClass('open');
            $span.removeClass('open');
        }
    });

    // Menü váltás scrollra
    $(document).scroll(function () {
        let $nav = $(".navbar");
        let $parallax = $('.parallax-container');
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });

});