$(function () {

    $(document).delegate(".contact form", "submit", function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.

        const $form = $(this);

        let $loader = '<div class="now playing" id="music">' +
            '  <span class="bar n1"></span>' +
            '  <span class="bar n2"></span>' +
            '  <span class="bar n3"></span>' +
            '  <span class="bar n4"></span>' +
            '  <span class="bar n5"></span>' +
            '  <span class="bar n6"></span>' +
            '  <span class="bar n7"></span>' +
            '  <span class="bar n8"></span>' +
            '</div>';

        let $submitBtn = $('.contact form fieldset:nth-of-type(6) p');
        $submitBtn.addClass('loading');

        const request = function () {
            let form = $form;
            let url = form.attr('action');
            let $success = $('.success');

            $submitBtn.append($loader);

            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize(), // serializes the form's elements.
                success: function(data)
                {
                    $success.addClass('open');
                    setTimeout(function () {
                        $success.removeClass('open');
                    }, 4000);

                    form[0].reset();
                    $("form :input").not($('input[type=submit]')).each(function(){
                        this.value = null;
                    });

                    $submitBtn.removeClass('loading');
                    $('.playing').fadeOut();

                    $('.parsley-errors-list').hide();

                    window.RecaptchaEvents.api.reset(form.find('#g-recaptcha').get(0));
                },

                error: function (data) {
                    {
                        $submitBtn.removeClass('loading');
                        let newHtmlString = data.responseJSON.html.form;
                        let newHtml = $.parseHTML(newHtmlString);
                        form.replaceWith(newHtml);

                        new window.RecaptchaEvents();
                    }
                }
            });
        };

        window.RecaptchaEvents.api.execute($form.find('#g-recaptcha').get(0)).then(function () {
            request();
        }).catch(function () {
            $submitBtn.removeClass('loading');
        })
    });

});
