$(function () {

    let defaultLimit = 0;
    let limitOffset = 0;
    let albumID = 0;

    if (window.serverVariables && window.serverVariables.itemLimit) {
        defaultLimit = window.serverVariables.itemLimit;
        limitOffset = window.serverVariables.itemLimit;
    }

    if (window.serverVariables && window.serverVariables.albumID) {
        albumID = window.serverVariables.albumID;
    }

    $(document).delegate(".gallery-show-more-btn", "click", function(e) {
        e.preventDefault();

        let url = window.serverVariables.galleryFileUrl + '?limit='+ defaultLimit +'&offset=' + limitOffset;

        $.ajax({
            type: "GET",
            url: url,
            data: {
                albumID: albumID
            },
            success: function(response)
            {
                if (response.items) {
                    const $items = $(response.items);
                    limitOffset += $items.length;
                    $items.hide();
                    $items.appendTo($('.image-wrapper'));

                    $items.each(function (index) {
                        $(this).delay(index * 200).fadeIn(500);
                    });
                }

                if ($(".image-wrapper").data("lightGallery")) {
                    $(".image-wrapper").data("lightGallery").destroy(true);
                    createLightGallery();
                }

                if(response.count <= limitOffset) {
                    $('.show-more-btn-wrapper').remove();
                }
            },

            error: function (error) {
                console.log(error);
            }
        });
    });

    function createLightGallery() {
        $('.image-wrapper').lightGallery({
            download: false,
            counter: false,
            loop: true,
            selector: '.lightbox-image-item',
            enableSwipe: true
        });
    }

    createLightGallery();
});


