$(function () {

    if($(window).width() > 1200) {
        function castParallax() {

            var opThresh = 350;
            var opFactor = 750;

            window.addEventListener("scroll", function(event){

                var top = this.pageYOffset;

                var layers = document.getElementsByClassName("parallax-item");
                var layer, speed, yPos;
                for (var i = 0; i < layers.length; i++) {
                    layer = layers[i];
                    speed = layer.getAttribute('data-speed');
                    var yPos = -(top * speed / 100);
                    layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px) translateX(-50%);transition: 0s all;transition-delay: 0s;');
                }
            });
        }

        document.body.onload = castParallax();
    }

});
