$(function () {

    let defaultLimit = 0;
    let limitOffset = 0;

    if (window.serverVariables && window.serverVariables.itemLimit) {
        defaultLimit = window.serverVariables.itemLimit;
        limitOffset = window.serverVariables.itemLimit;
    }

    $(document).delegate(".show-more-btn", "click", function(e) {
        e.preventDefault();

        let url = window.serverVariables.concertFileUrl + '?limit='+ defaultLimit +'&offset=' + limitOffset;

        $.ajax({
            type: "GET",
            url: url,
            success: function(response)
            {
                if (response.items) {
                    const $items = $(response.items);
                    limitOffset += $items.length;
                    $items.hide();
                    $items.insertBefore($('.show-more-btn-wrapper'));

                    $items.each(function (index) {
                        $(this).delay(index * 200).fadeIn(500);
                    });
                }

                if(response.count <= limitOffset) {
                    $('.show-more-btn-wrapper').remove();
                }
            },

            error: function (error) {
                console.log(error);
            }
        });
    });
});
